<template>
  <div
    class="" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card flat style="border-radius: 6px;" class="mt-0">
            <v-card-text>
              <div class="font-weight-medium body-1" :style="`color: ${set_color}`">
                Berita Terbaru
              </div>
              <v-divider class="mb-0 mt-2"></v-divider>
              <div v-for="(item, index) in process.load ? 8 : list" :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.load"
                  type="list-item-three-line">
                  <v-card flat class="cursor-pointer" :to="`/berita/${item.slug}?q=${item.id}`" @mouseover="voiceHover(item.title)">
                    <div v-if="!process.load && list.length > 0">
                      <div class="d-flex py-2">
                        <div>
                          {{ index+1 }}.
                        </div>
                        <div class="pl-2">
                          {{ item.title }}
                        </div>
                      </div>
                      <v-divider
                        v-if="index < 10 - 1"
                      ></v-divider>
                    </div>
                  </v-card>
                </v-skeleton-loader>
              </div>
            </v-card-text>
          </v-card>

          <v-card flat style="border-radius: 6px;" class="mt-6">
            <v-card-text>
              <div class="font-weight-medium body-1" :style="`color: ${set_color}`">
                Berita Terpilih
              </div>
              <v-divider class="mb-0 mt-2"></v-divider>
              <div v-for="(item, index) in process.load ? 8 : highlight" :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.load"
                  type="list-item-three-line">
                  <v-card flat class="cursor-pointer" :to="`/berita/${item.slug}?q=${item.id}`" @mouseover="voiceHover(item.title)">
                    <div v-if="!process.load && highlight.length > 0">
                      <div class="d-flex py-2">
                        <div>
                          {{ index+1 }}.
                        </div>
                        <div class="pl-2">
                          {{ item.title }}
                        </div>
                      </div>
                      <v-divider
                        v-if="index < 10 - 1"
                      ></v-divider>
                    </div>
                  </v-card>
                </v-skeleton-loader>
              </div>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    props: {

    },
    data () {
      return {
        list: [],
        highlight: [],
        process: {
          load: false,
          category: false
        },
        message: {
          success: '',
          error: ''
        },
      }
    },
    head () {


    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        console.log(this.$store.state.header.config.color)
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetch () {
        this.process.load = true

        let params = {
          limit: 5
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response
          this.fetchHighlight()

          if (res.status === 200) {
            this.list = res.results.data
          }
        })
      },
      async fetchHighlight () {
        this.process.load = true

        let params = {
          limit: 1000000000000,
          is_highlight: "1"
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response
          this.process.load = false

          if (res.status === 200) {
            this.highlight = res.results.data
          }
        })
      },
      toDetailBerita(item) {
        this.$router.push(`/berita/${(item.title.split('-').join('=').split(' ').join('-').split('/').join('>'))}?q=${item.id}`)
      },
    }
  }
</script>
